import React from "react"
import {
  DiDjango,
  DiBootstrap,
  DiCss3,
  DiHtml5,
  DiJsBadge,
  DiLinux,
  DiPostgresql,
  DiPython,
  DiReact,
  DiTerminal,
  DiRasberryPi,
  DiUbuntu,
} from "react-icons/di"
import { FaDocker } from "react-icons/fa"
import ros from "../../images/icons/Ros_logo.svg"
import arduino from "../../images/icons/Arduino-01.svg"
import ansible from "../../images/icons/Ansible_logo.svg"

const TechCard = ({ tech }) => {
  return (
    <div>
      <div className="">
        <div data-aos="zoom-in-up" className="">
          <div className="lato text-base lg:text-xl text-left text-gray-700">
            Wir sprechen Ihre Sprache
          </div>
          <div className=" mb-2 lato text-2xl lg:text-4xl text-left tracking-widest text-gray-800">
            Softwareentwicklung
          </div>
          <div className="">
            <div className=" text-gray-800 text-sm lg:text-base">
              Die Basis unseres Kompetenzprofils bilden zunächst
              Softwarelösungen geschrieben in <b>Python</b>. Diese umfassen
              neben der Anwendung in Backend Lösungen ebenfalls das Entwickeln
              von Datenanalysetools oder das Implementieren von Machine Learning
              Algorithmen. Ergänzend dazu verwenden wir <b>JavaScript</b> im
              Frontendbereich. Hier kommen moderne Frameworks und Bibliotheken
              zum Einsatz. Für hardwarenahe Lösungen verwenden wir <b>C++</b>{" "}
              und <b>C</b>. Neben der Entwicklung übernehmen wir gerne ebenfalls
              Aufgaben aus der Administration und dem Deployment. So
              unterstützen wir beispielsweise bei dem Einrichten von{" "}
              <b>Nginx</b> oder <b>Apache</b> Servern, dem Erstellen von
              Containerlösungen mittels <b>Docker</b>. Wir erstellen
              automatisierte
              <b> CI/CD-Pipelines</b> oder automatisieren Ihr Deployment mit{" "}
              <b>Ansible</b>.
            </div>
            <div className="flex justify-between my-10">
              <DiPython className="text-5xl" color="#2d3748" />
              <DiJsBadge className="text-5xl" color="#2d3748" />
              <div className="w-12">
                <svg
                  viewBox="0 0 128 128"
                  className="fill-current text-gray-800"
                >
                  <path d="M117.5 33.5l.3-.2c-.6-1.1-1.5-2.1-2.4-2.6l-48.3-27.8c-.8-.5-1.9-.7-3.1-.7-1.2 0-2.3.3-3.1.7l-48 27.9c-1.7 1-2.9 3.5-2.9 5.4v55.7c0 1.1.2 2.3.9 3.4l-.2.1c.5.8 1.2 1.5 1.9 1.9l48.2 27.9c.8.5 1.9.7 3.1.7 1.2 0 2.3-.3 3.1-.7l48-27.9c1.7-1 2.9-3.5 2.9-5.4v-55.8c.1-.8 0-1.7-.4-2.6zm-35.5 32.5v-4h5v-5h5v5h5v4h-5v5h-5v-5h-5zm3.3-14c-4.2-7.5-12.2-12.5-21.3-12.5-13.5 0-24.5 11-24.5 24.5s11 24.5 24.5 24.5c9.1 0 17.1-5 21.3-12.4l12.9 7.6c-6.8 11.8-19.6 19.8-34.2 19.8-21.8 0-39.5-17.7-39.5-39.5s17.7-39.5 39.5-39.5c14.7 0 27.5 8.1 34.3 20l-13 7.5zm29.7 14h-5v5h-4v-5h-6v-4h6v-5h4v5h5v4z"></path>
                </svg>
              </div>
              <div className="w-12">
                <img src={ansible} alt=""/>
              </div>
              <FaDocker className="text-5xl" color="#2d3748"/>
            </div>
          </div>
        </div>
        <div className="h-20"></div>

        <div data-aos="zoom-in-up" className="">
          <div className="lato text-base lg:text-xl text-left text-gray-700">
            Vom Front-, zum Backend
          </div>
          <div className=" mb-2 lato text-2xl lg:text-4xl tracking-widest text-gray-800">
            Webtechnologien
          </div>
          <div className=" text-gray-800 text-sm lg:text-base">
            Wir bieten Ihnen die vollumpfängliche Entwicklung von
            Webapplikationen. Ob Front-, oder Backend, wir fühlen uns auf beiden
            Seiten des Fullstacks wohl. Hierbei setzen wir auf pythonbasierte
            Backends in <b>Django</b> oder <b>Flask</b> sowie SQL-Datenbanken.
            Zudem bieten wir maßgeschneiderte Frontend Lösungen in <b>React</b>{" "}
            und den entsprechenden Bibliotheken aus dem React Umfeld. Auf den
            Kunden angepasste Designs in <b>CSS</b> sowie Frameworks wie{" "}
            <b>Bootstrap</b> oder <b>Tailwind</b> runden unser Angebot ab.
          </div>
          <div className="flex justify-between my-10">
            <DiHtml5 className="text-5xl" color="#2d3748" />
            <DiCss3 className="text-5xl" color="#2d3748" />
            <DiDjango className="text-5xl" color="#2d3748" />
            <DiReact className="text-5xl" color="#2d3748" />
            <DiBootstrap className="text-5xl" color="#2d3748" />
            <DiPostgresql className="text-5xl" color="#2d3748" />
          </div>
        </div>

        <div className="h-20"></div>
        <div data-aos="zoom-in-up" className="">
          <div className="lato text-base lg:text-xl text-left text-gray-700">
            IoT und Industrie 4.0
          </div>
          <div className=" mb-2 lato text-2xl lg:text-4xl tracking-widest text-gray-800">
            Hardwarenahe Programmierung
          </div>
          <div className=" text-gray-800 text-sm lg:text-base">
            Ob in <b>Mikrocontrollern</b> zur Erfassung von <b>Sensordaten</b>{" "}
            oder Bahnplanungsalgorithmen in Roboterarmen. Als Physiker und
            Automatisierungstechniker greifen wir auf umfangreiche Erfahrungen
            in der <b>harwarenahen Programmierung</b> in C++ und C zurück und
            fühlen uns in <b>Linux</b> Betriebssystemen sowie auf
            Entwicklungsplattformen wie <b>Raspberry Pi</b> oder <b>Arduino</b>{" "}
            wohl. Zusätzlich bieten wir Implementierungen in mobilen Robotern
            oder Leichtbaurobotern mithilfe von <b>ROS</b>.
          </div>
          <div className="flex justify-between my-10">
            <DiTerminal className="text-5xl" color="#2d3748" />
            <DiLinux className="text-5xl" color="#2d3748" />
            <DiUbuntu className="text-5xl" color="#2d3748" />
            <DiRasberryPi className="text-5xl" color="#2d3748" />
            <div className="w-16 flex items-center fill-current text-gray-800">
              <img
                src={arduino}
                alt=""
                className="fill-current text-gray-800 "
              />
            </div>
            <div className="w-16 flex items-center fill-current text-red-800">
              <img src={ros} alt="" className="fill-current text-red-800" />
            </div>
          </div>
        </div>
        <div className="h-48"></div>
      </div>
    </div>
  )
}

export default TechCard
