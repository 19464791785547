import React from "react"
import TechCard from "./TechCard"
import { Element } from "react-scroll"

const TechContent = () => {
	const tech = { color: "#e76f51" }

	return (
		<div className="">
			<Element name="content" className="element">
				<TechCard className="" tech={tech} />
			</Element>
		</div>
	)
}

export default TechContent
