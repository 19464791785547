import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import TechContent from "../components/Tech/TechContent"
import TechHero from "../components/Tech/TechHero"
import Back from "../components/Layout/Back"
import Navigation from "../components/Layout/Navigation"

const TechPage = () => (
	<Layout>
		<SEO
			title="Tech"
			description="Wir bieten unsere Kernkompentenz in der Softwareentwicklung in Python, C++ und Javascript, den Webtechnologien und entsprechenden Frameworks wie React, sowie der hardwarenahen Programmierung an."
		/>
		<Back color={"#e9c46a"} />
		<Navigation contact={false} active="tech" />
		<TechHero />
		<TechContent />
	</Layout>
)

export default TechPage
